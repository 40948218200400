import React from 'react';
import TableCalendar from '../../component/TableCalendar';
import * as WQC from './WQCComponent';
import {getOptions} from '../../util/commonInput';
import {axiosPost,server} from '../../comRedux/axiosPost';

export default class InputMatrix extends React.Component{
    constructor(props){
        super(props);
        this.unitOptions = [];
        this.state = {
          superGrant : false,
        }

        this.tableWrap_style = {   // <div> テーブルのwapper
            marginBottom:"16px",
        }
        this.table_style = {       // <table> テーブル
            width : "100%",
        }
        this.tr_color = {
            backgroundColor:"#fff",
        }

        this.column_style = {
            background : WQC.InputTableColor1,
            color:WQC.InputTableColorF,
        }

        this.text_style = {  // <input> テキストボックスのスタイル
            width:"100%",
            backgroundColor:"transparent",
            border:"none",
            height:25,
            outline:"none",
            boxSizing:"border-box",
            padding:"0px 5px",
          }

        this.td_style = {            // <td> 各セル
            // width:"100%",
            textAlign:"center",
            verticalAlign:"middle",
            border:"1px solid #ccc",
            boxSizing:"border-box",
            transition:"0.2s",
            height : 25,
            cursor:"pointer"
        }
        
        this.th_style = {                      // <td> テーブルヘッダー
            border:"1px solid #ccc",
            backgroundColor:WQC.InputTableColor1,
            fontSize:14,
            height:25,
            verticalAlign:"middle",
            // padding:8,
            color:WQC.InputTableColorF,
            fontWeight:"bold",
            textAlign:"center",
          }

          //単位表示用のスタイル
        this.unit_style = {
            marginLeft: "5px",
            paddingRight: "5px",
            fontSize: "14px",
            color: "#666"
        }
        // 入力欄と単位を横並びにするためのコンテナスタイル
        this.input_container_style = {
          display: "flex",
          alignItems: "center",
          width: "100%"
      }

        this.td_hed_space =      // <td> ヘッダーのスペース
            Object.assign({},this.th_style, this.column_style, { width: "30px" })
        
        this.td_hed_lbl = 
            Object.assign({},this.th_style,this.td_style,{width: "150px" })

        this.td_hed_lbl_oth1 = 
            Object.assign({},this.td_style,{background:"rgb(43, 64, 108)",color:"#FFF"})
            
        this.td_hed_lbl_oth2 = 
            Object.assign({},this.td_hed_lbl,{width: "60px"})  

        this.row_style = (rowIndex) => {return rowIndex % 2 ? {backgroundColor:WQC.InputTableColor2}:{backgroundColor:"#fff"};}

    }

      // 画面ロード時
  async componentDidMount() {
    await getOptions('/name-WQC/search?key=UNIT').then( res => this.unitOptions = res, err=> console.log(err));
    await axiosPost.post(server+'/top/getRegUser')
    .then((res) => {
      // console.log(res.data);
      if (res.data != null) {
        // console.log(res.data.superGrant);
        this.setState({ 
          superGrant     : res.data.superGrant,
        });
      } 
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
  }

    convertArray = (array, type) => {
    
      // カンマ区切りの文字列を配列に変換
      if (typeof array === "string") {
        array = array.split(",");
      }
    
      array = this.formatOptions(array);
    
      if (Array.isArray(array)) {
        if (array.length > 0 && typeof array[0] === "object" && "label" in array[0] && "value" in array[0]) {
          return array;
        }
        const convertedArray = array.map(data => ({
          value: data,
          label: data
        }));
    
        switch (type) {
          case "checkList":
            return convertedArray;
          case "radioList":
            return convertedArray;
          default:
            return [{ value: "", label: "" }, ...convertedArray];
        }
      } else {
        return [];
      }
    };
    

      formatOptions(array) {
        if (!Array.isArray(array)) {
          console.error("formatOptions expects an array, but received:", array);
          return [];
        }
        return array
          .filter(item => item !== "") // 空文字列を除外
          .map(item => ({
            label: item,
            value: item
          }));
      }
      
      //単位を取得して必要な情報だけを返す
      getUnitFromOptions(value, options) {
        // 該当するvalueのlabelを見つける
        const option = options.find(opt => opt.value === value);
        
        // optionが見つからない、またはlabelが空の場合は空文字を返す
        if (!option || !option.label) {
          return '';
        }
        
        // label から | 以降の部分を抽出して余分な空白を削除
        const unit = option.label.split('|')[1];
        return unit ? unit.trim() : '';
      }
      

    render(){
        
      const typelist = ["date","txt","cmb","chk"]

        // const{group} = this.props; //Matrix対象のデータ
        const {group,tblHdLbl1,tblHdLbl2,groupIndex,disabled,handleInputChange,bean} = this.props;
        const accuracyHname = "精度管理連携項目";
        return(
          (bean && bean.length > 0) &&
            bean.map((group, groupIndex) =>{
                return(
                <>
                <b>{"【"+group.groupName+"】"}
                {group.accuracyDataMake ? "※精度管理連携" : null}</b>
                <font color="red" size="-1">{group.info ? "※"+group.info : null}</font>
                <div style={this.tableWrap_style}>
                  <table style={this.table_style}>
                    <tr style={this.tr_color}>
                      <td style={this.td_hed_space}></td>
                      <td style={this.td_hed_lbl}>{tblHdLbl1}</td>
                      {/*テーブルヘッダー名設定*/}
                      {group.hInputItemArray.map((header, index) => (
                        <td key={index} style = {this.td_hed_lbl_oth1}>{header}</td>
                      ))}
                      {group.displayUnit ? <td style = {this.td_hed_lbl_oth2}>{tblHdLbl2}</td> : null}
                      {/*精度管理連携項目*/}
                      {group.accuracyDataMake && this.state.superGrant ?<td colSpan="3" style = {this.td_hed_lbl}>{accuracyHname}</td> : null}
                    </tr>
                    <tbody>
                    {group.detailFormBean.map((row, rowIndex) => (
                      <tr key={rowIndex} style={this.row_style(rowIndex)}>
                        <td style={Object.assign({},this.th_style, this.column_style,{ width: "30px" })}>{rowIndex+1}</td> 
                        <td style={Object.assign({},this.td_style,{ width: "150px",textAlign:"left",paddingLeft:5,})}>{row.inputItem}</td>

                        {/* データ入力セル */}
                        {row.dataValues.map((value, colIndex) => (
                          group.hInputSettingArray[colIndex]  ? (
                          <TD disabled={disabled}>
                            <div style={this.input_container_style}>
                              <Input 
                                type={group.hInputTypeArray[colIndex]}
                                value={value} 
                                options={this.convertArray(group.hInputSettingArray[colIndex],group.hInputTypeArray[colIndex])}
                                handleInputChange={handleInputChange} 
                                onChange={(e) => {handleInputChange(groupIndex, rowIndex, colIndex, e.target.value)}}
                                disabled={disabled} 
                                style={this.text_style} 
                                groupIndex={groupIndex} 
                                rowIndex={rowIndex} 
                                colIndex={colIndex}
                              />
                              {group.hInputUnitArray[colIndex] && <span style={this.unit_style}>{this.getUnitFromOptions(group.hInputUnitArray[colIndex],this.unitOptions)}</span>}
                            </div>
                          </TD>
                          ) : (
                          <TD disabled={disabled}>
                            <div style={this.input_container_style}>
                            <Input type={group.hInputTypeArray[colIndex]}
                              value={value} 
                              handleInputChange={handleInputChange} 
                              onChange={(e) => {handleInputChange(groupIndex, rowIndex, colIndex, e.target.value)}}
                              disabled={disabled} style={this.text_style} groupIndex={groupIndex} rowIndex={rowIndex} colIndex={colIndex}/>
                              {group.hInputUnitArray[colIndex] && <span style={this.unit_style}>{this.getUnitFromOptions(group.hInputUnitArray[colIndex],this.unitOptions)}</span>}                           </div>
                          </TD>
                          )
                        ))}
                        {row.inputUnit ? <td style={this.td_style}>{row.inputUnit}</td> :null}
                        {group.accuracyDataMake  && this.state.superGrant ? <td style={Object.assign({},this.td_style,{ width: "100px",textAlign:"left",paddingLeft:5,})}>{row.accuracyItem}</td> :null}
                        {group.accuracyDataMake  && this.state.superGrant ? <td style={Object.assign({},this.td_style,{ width: "30px",textAlign:"right",paddingLeft:5,})}>{row.accuracyLotLevel}</td> :null} 
                        {group.accuracyDataMake  && this.state.superGrant ? <td style={Object.assign({},this.td_style,{ width: "100px",textAlign:"center",paddingLeft:5,})}>{row.accuracyLotNo}</td> :null}                            
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
                </>
                )
                }
            ))
    }
}

class TD extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hover : false,
        focus : false,
      }
    }
  
    handleOnBlur = () => {
      const { onBlur } = this.props;
      this.setState({ focus : false });
      onBlur && onBlur()
    }
  
    render() {
      const { hover, focus } = this.state           // 省略宣言
      const { type, style, disabled } = this.props
  
      const td_style = {            // <td> 各セル
        textAlign:"center",
        verticalAlign:"middle",
        border:"1px solid #ccc",
        boxSizing:"border-box",
        transition:"0.2s",
        height : 25,
        boxShadow : 
          disabled ? null :
          (type === 'label' )? null :
          focus ? "0px 0px 2px 0px #00f inset" :
          hover ? "0px 0px 5px 0px #00f inset" :
          null,
        backgroundColor:
          disabled ? "#f7f7f7" :
          focus ? "#eef" :
          null,
        cursor:
          disabled ? null :
          (type === 'label') ? null :
          "pointer",
      }
  
      return (
        <td
          style        = {Object.assign( {}, td_style, style ) } 
          onClick      = {this.props.onClick }
          onMouseOver  = {()=> this.setState({ hover : true  }) }
          onMouseLeave = {()=> this.setState({ hover : false }) }
          onFocus      = {()=> this.setState({ focus : true  }) }
          onBlur       = {this.handleOnBlur}
        >
          { this.props.children }
        </td>
      );
    }
  }
  
  class Input extends React.Component{
    constructor(props){
      super(props);
      const { disabled,color } = this.props;
      this.select_style = {
        height:25,
        width: "100%",
        textAlign:"left",
        paddingLeft:5,
        backgroundColor : "transparent",
        border : "none",
        boxSizing:"border-box",
        cursor : 
          disabled ? null :
          "pointer",
      }
  
      this.boxColor = color ? color:"#777";
  
      this.wrap_style = {
        // display : "flex",
        alignItems:"center",
        height:"100%",
        justifyContent:"center"
      }
  
      this.boxWrap_style = {
        display:"inline-flex",
        padding:0,
        border:"none",
        backgroundColor:"transparent",
        alignItems:"center",
        boxSizing:"border-box",
        cursor:"pointer",
        fontSize:16,
        margin:'0 0 0 5px',
        verticalAlign:"middle",
      }
  
      this.box_style = {
        height:20,
        width:20,
        boxSizing:"border-box",
        borderRadius:5,
        borderStyle:"solid",
        borderWidth:"2px",
        borderColor:this.boxColor,
        position:"relative",
      }
  
      this.box_style_checked = {
        height:20,
        width:20,
        boxSizing:"border-box",
        borderRadius:5,
        borderStyle:"solid",
        borderWidth:"2px",
        borderColor:this.boxColor,
        position:"relative",
        backgroundColor:"rgb(119, 119, 119)",
      }
      
      this.checked_style = {
        position:"absolute",
        bottom:2,
        left:3,
        boxSizing:"border-box",
        height:15,
        width:10,
        borderRight: "4px solid white",
        borderBottom: "4px solid white",
        transform: "rotate(45deg)",
        borderRadius:5,
      }
  
      this.span_style = {
        verticalAlign:"middle",
      } 
    }
  
    handleCalendarChange = (value) =>{
      const {groupIndex,rowIndex,colIndex} = this.props;
      this.props.handleInputChange(groupIndex,rowIndex,colIndex,value);
    }
  
    chkBoxListOnClick = (e) => {
      const { groupIndex, rowIndex, colIndex, value } = this.props;
      let inputValue = e.target.getAttribute('data-value') || '';
      let valuesArray = value ? value.split(',') : [];
      // inputValueがnullまたはundefinedの場合に空文字列を代入
      if (!inputValue) {
        inputValue = '';
      }
      if (valuesArray.includes(inputValue)) {
          valuesArray = valuesArray.filter(item => item !== inputValue);
      } else {
          valuesArray.push(inputValue);
      }
      inputValue = valuesArray.join(',');
      this.props.handleInputChange(groupIndex, rowIndex, colIndex, inputValue);
  }


  chkBoxListOnClick = (e) => {
    const { groupIndex, rowIndex, colIndex, value } = this.props;
    let inputValue = e.target.getAttribute('data-value') || '';
    let valuesArray = value ? value.split(',') : [];
    // inputValueがnullまたはundefinedの場合に空文字列を代入
    if (!inputValue) {
      inputValue = '';
    }
    if (valuesArray.includes(inputValue)) {
        valuesArray = valuesArray.filter(item => item !== inputValue);
    } else {
        valuesArray.push(inputValue);
    }
    inputValue = valuesArray.join(',');
    this.props.handleInputChange(groupIndex, rowIndex, colIndex, inputValue);
}

  chkBoxListRadioOnClick = (e) => {
    const { groupIndex, rowIndex, colIndex, value } = this.props;
    let inputValue = e.target.getAttribute('data-value') || '';

    // inputValueがnullまたはundefinedの場合に空文字列を代入
    if (!inputValue) {
      inputValue = '';
    }

    // 入力された値をチェックして、既に選択されているか確認
    if (value === inputValue) {
      // 既に選択されている場合、値を削除（空にする）
      inputValue = '';
    } 

    this.props.handleInputChange(groupIndex, rowIndex, colIndex, inputValue);
  }


  
    render(){
      const {type,value,disabled,style,options} = this.props;
      let comp = null;
      const optArr = [];
  
      // option を作成するループ
      if(options && type ==='cmb') {
        for(let i in options){
          const { value, label } = options[i];
          optArr.push(
            <option key={value} value={value}>{label}</option>
          )
        }
      }
  
      switch(type){
        case 'chk':{
              comp = (
              <div style={ this.wrap_style }> 
                {options && options.map((option, index) => (
                <React.Fragment key={index}>
                  <button style={ this.boxWrap_style } onClick={(e) => this.chkBoxListOnClick(e)} disabled={disabled}>
                    {/* それぞれのチェックボックス毎のON,OFFを管理 */}
                    {value && option.value && value.indexOf(option.value) !== -1 ? (
                      <div style = { Object.assign({}, this.box_style_checked)} data-value={option.value}>
                        <div style={this.checked_style} data-value={option.value}></div>
                      </div>
                    ) : (
                      <div style = { Object.assign({}, this.box_style)} data-value={option.value}></div>
                    )}
                  </button>
                  <span style={this.span_style}>{ option.label }</span>
                </React.Fragment>
              ))}
              </div>
              );
              break;
        }
        case 'radio':{
          comp = (
          <div style={ this.wrap_style }> 
            {options && options.map((option, index) => (
            <React.Fragment key={index}>
              <button style={ this.boxWrap_style } onClick={(e) => this.chkBoxListRadioOnClick(e)} disabled={disabled}>
                {/* それぞれのチェックボックス毎のON,OFFを管理 */}
                {value && option.value && value.indexOf(option.value) !== -1 ? (
                  <div style = { Object.assign({}, this.box_style_checked)} data-value={option.value}>
                    <div style={this.checked_style} data-value={option.value}></div>
                  </div>
                ) : (
                  <div style = { Object.assign({}, this.box_style)} data-value={option.value}></div>
                )}
              </button>
              <span style={this.span_style}>{ option.label }</span>
            </React.Fragment>
          ))}
          </div>
          );
          break;
    }
        case 'cmb':{
          comp = (
            <select value = {value} disabled = {disabled} onChange={this.props.onChange}style = {this.select_style}>
            {optArr}
            </select>
          );
          break;
        }
        case 'date':{
          comp = <TableCalendar value={value} onChange={this.handleCalendarChange} disabled={disabled} style={style} />
          break;
        }
        case 'num':{
          comp = <input value={value} onChange={this.props.onChange} disabled={disabled} style={Object.assign({},style,{textAlign:"right"})} type={"tel"}/>
          break;
        }
        case 'txt':{
          comp = <input value={value} onChange={this.props.onChange} disabled={disabled} style={style}/>
          break;
        }
        default:{
          comp = <input value={value} onChange={this.props.onChange} disabled={disabled} style={style}/>
        }
      }
      return(
        comp
      );
    }
  }
